
/**zh.js文件**/
module.exports = {
   content: {
    wallet: '鏈接錢包',
    time: '開始倒計時',
    merge: '合併',
    treasure: '購買寶箱',
    pool: '獎金池',
    total: '獎金總額',
    rank: '排行榜',
    height: '高度',
    volume: '體積',
    speed: '速度',
    price: '價格',
    amount: '數量',
    accredit: '授權額度', 
    buy: '購買',
    connect: '鏈接錢包',
    login: '登錄',
    award: '獎勵日期',
    mining: '挖礦準備中',
    walletTip: '請鏈接錢包',
    uboxTip: '請登錄Metapeak',
    connected: '錢包已鏈接',
    signUbox: '已登錄Metapeak',
    poolLevel: '獎池<br/>等級',
    numberOfPeople: '獲獎<br/>人數',
    estimated: '預計<br/>獎金',
    noOpen: '暫未開放',
    settled: '已結算',
    obtain: '獲取獎金',
    orderRecord: '訂單記錄',
    props: '獲取道具',
    netWork: "請鏈接正確網絡",
    noWallet: '請先鏈接錢包',
    noBallonId: '此氣球沒有關聯寶箱',
    noChainId: '請鏈接正確網絡',
    noPrice: '區塊鏈網絡异常，獲取寶箱價格失敗',
    waitGame: '等待遊戲開始',
    noAccredit: '您已拒絕授權',
    noLogin: '令牌過期，請重新登陸',
    pleaseAccredit: '請授權额度',
    noPay: '拒絕支付',
    noBallon: '沒有氣球',
    opening: '開啟中',
    noMoney: '轉賬金額大於授權金額',
    ruleContent: '快速掌握閱讀規則簡介',
    Ballon: '我的氣球',
    miningBallon: '挖礦氣球',
    gameBallon: '比賽氣球',
    harvestBallon: '獎勵氣球',
    equipped: '已裝備',
    chongqinomal: '充氣罐',
    fangyu:'防雨塗層',
    huojian: '火箭',
    superhuojian: '超級火箭',
    upzhitongche: '直通車票',
    di_xc: '小充氣罐',
    dj_dc: '大充氣罐',
    dj_zc: '中充氣罐',
    dj_js: '加速器',
    noBallonTip: '沒有找到你的氣球<br/>請前往 NFT 市场<br/>購買氣球',
    purchased: '已購買',
    ToUbox: '前往 NFT 市场',
    chestWay: '購買成功 寶箱正在路上',
    continue: '繼續購買',
    noAccredit2: '轉賬金額大於授權金額,請重新授權',
    noUse: '比賽開始后自動使用',
    totalRule: '我們采用去中心化式瓜分獎金規則，自比赛开始算起，以太坊每產生30000個區塊(約5.12天)，則進行一次取模和哈希隨機數的計算，得出本次瓜分的比例。',
    validity: '有效期',
    received: '已獲得',
    chestWay2: '寶箱正在路上，請耐心等待',
    ballonNo: '氣球編號',
    ballonContract: '氣球合約地址',
    ballonSpeed: '氣球當前上升速度',
    ballonMaterials: '氣球材料',
    meterial: '碳纖維和橡膠混合物',
    ballonQuality: '氣球材料質量',
    ballonGas: '氣球內部氣體',
    gasInside: '氦氣和氮氣混合物',
    gasDensity: '氣球内部的氣體密度',
    ballonCurrentVolume: '氣球當前體積',
    hkWeather: '香港天氣數據',
    temperature: '溫度',
    humidity: '濕度',
    airDensity: '空氣密度',
    windSpeed: '風速',
    windRain: '雨天',
    sunnyNight: '晴空夜晚',
    weatherWind: '多云',
    rule: '規則',
    sunny: '晴天',
    remainning: '剩餘时间',
    startvalidity: '正在開始生效',
    miningRecords: '挖礦獎勵',
    recordTip: '恭喜您挖礦成功！',
    recordSuccess: ' 個氣球，已放入您的背包中',
    features: '氣球屬性',
    ticketError: '登陆超时',
    passHeight: '到达此高度获得一个气球',
    find: '查看',
    information: '比賽信息',
    playBall: '比賽氣球',
    cutBall: '挖礦氣球',
    enterBall: '輸入氣球編號進行查詢',
    mined: '已挖出氣球',
    noProperty: '沒有道具',
    racing: '22-小時头奖对决',
    rankList: '排行榜',
    myRank: '我的排名',
    advanced: '成功晉級',
    youBallon: '您的氣球',
    ranked: '在本次比赛中獲得第{rank}名',
    failAdvance: '晉級失敗',
    raise: '未能提升至{meter}M',
    fialTip: '提示: 改進氣球屬性可以提高氣球氣球速度',
    continueGame: '繼續遊戲',
    competitionBegin: '{level}級比賽即將開始',
    winHigher: '贏得更高獎金',
    congratulations: '恭喜',
    winTip: '晉級成功',
    championTip: '現時，獎金池中有{number}人共亯。現在，您有權在這裡進行永久質押，並從中獲得獎金池利潤',
    pledge: '質押',
    obtaining: '去瓜分獎金',
    workHard: '正在努力挖礦',
    toLevel: '到晉級綫',
    toPool: '到獎金池',
    openError: '寶箱開啓失敗,請去訂單列表開啓',
    freeBox: '免費寶箱',
    receiveSuccess: '免費寶箱領取成功',
    receiveFail: '領取失敗',
    end: '這場游戲已經結束',
    use: '確定',
    settle:'結算中',
    nextGame: '距離下一場比賽',
    noFind: '未查詢到{num}編號氣球信息',
    settleTip: '恭喜!<br/>你已獲得終極大獎，<br/>氣球已自動質押!',
    go: '衝啊!',
    noRecords: '沒有訂單記錄',
    useCar: '已使用直通車',
    pledgeSuccess: '質押成功',
    pledgeFail: '質押失敗',
    remainder: '剩餘時間',
    findBallon: '查詢氣球',
    input: '輸入編號',
    install: '請安裝metamask',
    homeGreeting: '"去月球2.0"是一款引人入胜的基于NFT的科学竞技游戏，是第一款基于真实空气动力学原理设计的游戏，创造了一款完全去中心化的web3寻宝游戏。',
    noChampion: '獲得冠軍',
   },
   metaMask: {
   loginError: '您尚未联接钱包'
   }
}
   