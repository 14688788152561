<template>
    <!-- ai的message弹窗内容 -->
      <transition name="taataa">
        <div class="plugins-message-box" v-if="visible">
          <div class="message-container">
            <img :src="require(`@/assets/img/${iconClass}.svg`)" alt="">
            <!-- <span class="message-container">{{message}}</span> -->
            <div class="spantext">{{message}}</div>
          </div>
        </div>
      </transition>
    </template>
    
    <script>
    const typeClass = {
      success: "duihao",
      error: "error",
      warning: "tanhao",
    };
    export default {
      name: "messageMain",
      data() {
        return {
          visible: false, // 控制DOM显示隐藏
          type: "success", // 默认type值为default
          icon: "", // 默认使用icon为空，则使用type值对应的icon
          message: "", // 默认的message为空，由外部传入
          duration: 2000, // 默认显示时间为2000ms
        };
      },
      computed: {
        // 如果外部传入icon则使用外部的icon，如果没有。则使用type值对应的icon
        iconClass() {
          if (this.icon) {
            return this.icon;
          } else {
            return typeClass[this.type];
          }
        },
      },  
    };
    </script>
    <style lang="scss" scoped>
    .plugins-message-box {
      position: fixed;
      z-index: 9999;
      left: 50%;
      top: 10%;
      transform: translate(-50%, 0%);
      background-color: #fff;
      border: 2px solid #000;
      padding: 10px;
      -webkit-animation: comein .5s  linear;
      animation: comein .5s  linear;
      pointer-events: none;
      .message-container {
        display: flex;
        align-items: center;
        img {
            width: 26px;
            height: 26px;
        }
        .spantext {
            font-size: desktop-vw(12px);
            margin-left: 12px;
            font-weight: bold;
        }
      }
    }
    @keyframes comein {
      0% {
        top: -20%;
        opacity: 0.3;
      }
      100% {
        top: 10%;
        opacity: 1;
      }
    }
    </style>