export function getSecond(timestamp) {
    if(timestamp > 0) {
        let hour = Math.floor(timestamp / 3600);
        let min = Math.floor((timestamp - hour * 3600) / 60);
        let sec = Math.floor((timestamp - hour * 3600 - min * 60) / 1);
        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        sec = sec < 10 ? "0" + sec : sec;
        return hour + ":" + min + ":" + sec;
        }
    }