const baseUrl = '/api/www'
export default {
    baseURL: baseUrl,

    getUBoxToken: baseUrl + '/UBox/getUBoxToken',
    getStartGmeTime: baseUrl + '/ballSystemConfig/getStartGmeTime',
    getTotalFunds: baseUrl + '/ballSystemConfig/getTotalFunds',
    getUserCollectionList: baseUrl + '/UBox/getUserCollectionList',
    createOrder: baseUrl + '/ballOrderRecords/createOrder',
    openBox: baseUrl + '/ballValuableBoxRecord/openBox',
    getBoxId: baseUrl + '/ballMatchesPlayed/getBoxId',
    findWeather: baseUrl + '/ballWeatherLog/findWeather',
    findByKey: baseUrl + '/ballBalloon/findByKey',
    getSysInfoByType: baseUrl + '/ballSystemConfig/getSysInfoByType',
    getSysInfoByTypes: baseUrl + '/ballSystemConfig/getSysInfoByTypes',
    getOrderRecordByOpenUid: baseUrl + '/ballOrderRecords/getOrderRecordByOpenUid',
    openBoxByHand: baseUrl + '/ballValuableBoxRecord/openBoxByHand',
    recordView: baseUrl + '/ballValuableBoxRecord/recordView',
    findByBallId: baseUrl + '/ballCompetitionDown/findByBallId',
    isgetBalloon: baseUrl + '/ballCompetitionDown/isgetBalloon',
    gameById: baseUrl + '/ballCompetitionDown/gameById',
    findByNum: baseUrl + '/ballBalloon/findByNum',
    getBallCurrentInfo: baseUrl + '/ballCompetition/getBallCurrentInfo',
    continueCompetition: baseUrl + '/ballCompetition/continueCompetition',
    championPledgeBall: baseUrl + '/ballCompetition/championPledgeBall',
    checkFreeBox: baseUrl + '/ballValuableBoxRecord/checkFreeBox',
    getFreeBox: baseUrl + '/ballValuableBoxRecord/getFreeBox',
    findByPage: baseUrl + '/ballHot/clickHot',
}