<template>
    <div class="bg" v-show="showloading">
      <div class="loading">
        <img v-if="showloading" :src='srcName' alt="" />
        <div class="loading_bottom">
          <p>Loading</p>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Loading",
    props: {
      showloading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      
    },
    watch: {
      showloading(val) {
        if (val) {
          console.log(this.$store,'random')
          this.srcName = this.imgList[this.$store.state.wallet.mathRandom].src;
        }
      },
    },
    data() {
      return {
          imgList: [
          {
              src: require("@/assets/img/dog.gif")
          },
          {
              src: require("@/assets/img/cow.gif")
          },
          {
              src: require("@/assets/img/mk.gif")
          },
          {
              src: require("@/assets/img/rb.gif")
          },
          {
              src: require("@/assets/img/sn.gif")
          }
      ],
      srcName: ''
      };
    },
    created() {
      
    },
    mounted() {
     
    }
  };
  </script>
  
  <style lang="scss" scoped>
  // @keyframes move {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }
  .bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
  //   .circle {
  //     width: 46px;
  //     height: 46px;
  //     border: 5px solid #fff;
  //     border-radius: 40px;
  //     border-left-color: transparent;
  //     animation: move linear 500ms infinite;
  //   }
    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border: 6px solid #000;
      padding: 20px 50px;
      img {
          width: 100px;
          height: 100px;
        }
      .loading_bottom {
        display: flex;
        align-items: flex-end;
        margin-left: 16px;
        color: #000;
        p {
          font-size: desktop-vw(16px);
        }
        div {
          width: 5px;
          height: 5px;
          background-color: #000;
          animation: bouncing-loader 0.6s infinite alternate;
          margin-left: 3px;
          &:nth-child(1) {
            margin-left: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }
    }
  }
  @keyframes bouncing-loader {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0.1;
      transform: translateY(1px);
    }
  }
  </style>
  