<template>
  <div class="header">
    <div class="header_left">
      <img src="@/assets/img/logo.svg" alt="" />
    </div>
    <slot></slot>
    <div class="header_right">
      <div class="login" @click="connectWallet" v-show="!$store.state.wallet.account||!$store.state.wallet.token">
        {{ $t("content.login") }}
      </div>
      <div class="login loginName" @click="connectWallet" v-show="$store.state.wallet.account&&$store.state.wallet.token">
        <div class="left">
            <img src="@/assets/img/ubox.svg" alt="">
        </div>
        <div class="account">
            {{ accountStr }}
        </div>
      </div>
      <div class="telegram" @click="telegram()">
        <img src="@/assets/img/telegram.svg" alt="">
      </div>
      <div class="twiter" @click="twiter()">
        <img src="@/assets/img/twitter.svg" alt="">
      </div>
      <language-select />
    </div>
    <my-dialog
      :show.sync="walletShow"
      @close="closeDialog"
      class="walletBix"
      :title="$t('content.login')"
    >
      <div class="wallet">
        <div class="walletTip" v-show="!$store.state.wallet.account">
          <img src="@/assets/img/red_tanhao.svg" alt="" />
          <p>{{ $t("content.walletTip") }}</p>
        </div>
        <div class="walletTip" v-show="$store.state.wallet.account">
          <img src="@/assets/img/duihao.svg" alt="" />
          <p style="color: #35ad22">{{ $t("content.connected") }}</p>
        </div>
        <div
          class="metaMask"
          :style="{ backgroundColor: active === 1 ? '#FFB30D' : '#fff' }"
          @click="wallet('metamask')"
          v-show="!$store.state.wallet.account"
        >
          <img src="@/assets/img/MetaMask.svg" alt="" />
          MetaMask
        </div>
        <div
          class="metaMask"
          style="cursor: default"
          v-show="$store.state.wallet.account"
        >
          <img src="@/assets/img/MetaMask.svg" alt="" />
          {{ accountStr }}
          <span class="logout" @click.stop="logout">Esc</span>
        </div>
        <div class="uboxTip" v-show="!$store.state.wallet.token">
          <img src="@/assets/img/red_tanhao.svg" alt="" />
          <p>{{ $t("content.uboxTip") }}</p>
        </div>
        <div class="uboxTip" v-show="$store.state.wallet.token">    
          <img src="@/assets/img/duihao.svg" alt="">
          <p style="color: #35AD22">{{ $t('content.signUbox') }}</p>
        </div>
        <div
          class="ubox"
          :style="{ backgroundColor: active === 2 ? '#FFB30D' : '#fff' }"
          v-show="!$store.state.wallet.token"
          @click="wallet('ubox','MP')"
        >
          <img src="@/assets/img/ubox.svg" alt="" />
          <p>Metapeak</p>
        </div>
        <!-- <div
          class="ubox"
          :style="{ backgroundColor: active === 3 ? '#FFB30D' : '#fff' }"
          v-show="!$store.state.wallet.token"
          @click="wallet('ubox','UBOX')"
        >
          <img src="@/assets/img/ubox.svg" alt="" />
          <p>UBOX</p>
        </div> -->
        <div class="ubox" style="cursor: default" v-show="$store.state.wallet.token">
          <img src="@/assets/img/ubox.svg" alt="">
          {{ $store.state.wallet.userName }}
          <span class="logout" @click.stop="logoutUbox">Esc</span>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import languageSelect from "@/components/languageSelect.vue";
import account from "@/utils/web3";
export default {
  name: "headerView",
  components: {
    languageSelect,
  },
  data() {
    return {
      walletShow: false,
      active: 0,
    };
  },
  computed: {
    accountStr() {
      let str1 = this.$store.state.wallet.account.substring(0, 5);
      let str2 = this.$store.state.wallet.account.substring(
        this.$store.state.wallet.account.length,
        this.$store.state.wallet.account.length - 4
      );
      return str1 + "..." + str2;
    },
  },
  methods: {
    connectWallet() {
      this.walletShow = true;
    },
    wallet(name, type) {
      if (name === "metamask") {
        this.active = 1;
        account.Init((val) => {
          console.log(val, "val");
          if (val) {
            this.$store.commit("wallet/updateAccount", val);
          }
          this.active = 0;
        });
      } else {
        if(type === 'MP') {
          this.active = 2;
        } else {
          this.active = 3;
        }
        //跳第三方网站
        //获取当前页面的url
        let url = window.location.href;
        this.$store.commit("wallet/updateWalletType", type);
        if(this.$store.state.wallet.walletType === 'MP') {
          // window.location.href = `https://test.hxnft.vip/Metapeak/#/login?redirect=${url}&apikey=${this.$apikey}`;
          window.location.href = `https://www.metapeak.io/#/login?redirect=${url}&apikey=${this.$apikey}`
        } else {
          window.location.href = `https://www-test1.imall.art/loginAuthorize?sourceId=1&redirectUrl=${url}&domainName=ubox.io`
        }
      }
    },
    closeDialog() {
      this.active = 0;
    },
    logout() {
      this.$store.commit("wallet/updateAccount", "");
    },
    logoutUbox() {
      this.$store.commit("wallet/updateToken", "");
      this.$store.commit("wallet/updateUid", "");
      // this.$store.commit("wallet/updateWalletType", "");
      this.$store.commit("wallet/updateUserName", "");
      window.history.replaceState(null, null, this.gelDelURL());
      // 判断是不是首页
      if (this.$route.path === "/") {
        window.location.reload();
      } else {
        this.$router.replace("/");
      }
    }, 
    gelDelURL() {
      return window.location.href.substring(0,window.location.href.indexOf("?"));
    },
    telegram() {
      window.open('https://t.me/ttmoonOGclub')
    },
    twiter() {
      window.open('https://twitter.com/TTMoon_NFT')
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 18.5px 0 18.5px;
  position: relative;
  @media screen and (max-height: 574px) {
      padding-top: 10px;
    }
  .header_left {
    img {
      width: 246.66px;
      height: 42.69px;
    }
  }
  .header_right {
    display: flex;
    align-items: center;
    .login {
      padding: 12px 51px;
      border: 8px solid #fff;
      font-size: desktop-vw(18px);
      color: #014ab0;
      background-color: #EBEBEB;
      margin-right: 12px;
      cursor: url('@/assets/img/zhizhen1.cur'), auto;
    }
    .loginName {
        display: flex;
        align-items: center;
        padding: 11px 31px 11px 11px;
        font-size: desktop-vw(18px);
        .left {
            display: flex;
            align-items: center;
            padding-right: 11px;
            border-right: 1px solid #fff;
            img {
                width: 26px;
                height: 26px;
            }
        }
        .account {
            padding-left: 18px;
        }
    }
    .telegram,.twiter {
      cursor: url('@/assets/img/zhizhen1.cur'), auto;
        width: 64px;
        height: 64px;
    }
    .twiter {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
  :deep(.walletBix) {
    .popup-content {
      .wallet {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .walletTip,
        .uboxTip {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: desktop-vw(14px);
          color: #ea4848;
          margin-bottom: 9px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }
        .metaMask,
        .ubox {
          display: flex;
          width: 100%;
          font-size: desktop-vw(18px);
          padding: 10px 0;
          align-items: center;
          justify-content: center;
          border: 4px solid #000;
          cursor: url('@/assets/img/zhizhen1.cur'), auto;;
          margin-bottom: 26px;
          padding: 6px 18px;
          img {
            width: 32px;
            height: 32px;
            margin-right: 13px;
          }
          .logout {
            font-size: desktop-vw(16px);
            color: #929292;
            text-decoration: underline;
            margin-left: auto;
            cursor: url('@/assets/img/zhizhen1.cur'), auto;;
          }
        }
      }
    }
  }
}
</style>
