<template>
  <div id="app">
    <router-view />
    <transition name="el-fade-in-linear">
      <div class="loading-box" v-show="showLoading">
        <div class="loading">
          <img :src='srcName' alt="" />
          <div class="loading_bottom">
            <p>Loading</p>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import apiUrl from "@/utils/apiUrl";

export default {
  name: "App",
  components: {
    
  },
  data() {
    return {
      rainShow: true,
      showLoading: true,
      timer: null,
      weathTimer: null,
      typeTimer: null,
      imgList: [
        {
            src: require("@/assets/img/dog.gif")
        },
        {
            src: require("@/assets/img/cow.gif")
        },
        {
            src: require("@/assets/img/mk.gif")
        },
        {
            src: require("@/assets/img/rb.gif")
        },
        {
            src: require("@/assets/img/sn.gif")
        }
    ],
    srcName: ''
    };
  },
  mounted() {
    if(window.ethereum){
      window.ethereum.on('accountsChanged', (accounts) => { //回调函数参数accounts是切换后的账户
      console.log('触发了', accounts)
      if (accounts.length === 0) {
        this.$store.commit("wallet/updateAccount", "");
        this.$store.commit("wallet/updateApproveUsdt", "");
        this.$store.commit('wallet/updateApproveUsdtOld',"")
        return
      }
      this.$store.commit("wallet/updateAccount", accounts[0]);
    });
    window.ethereum.on('chainChanged', (chainId) => { //回调函数参数chainId是切换后的链ID
      console.log('触发了', chainId)
      // if (chainId !== '0x5a2') {
      if (chainId !== '0x89') {
        this.$message({
          message: this.$t('content.noChainId'),
          type: 'warning'
        });
        this.$store.commit("wallet/updateAccount", "");
      }
      window.location.reload()
    });
    }
    window.addEventListener('visibilitychange',()=>{
      this.$store.commit('wallet/updateDocmentVisibility', document.hidden ? false : true);
    })
  },
  created() {
    const that = this;
    this.$store.commit('wallet/updateMathRandom',Math.floor(Math.random() * 4))
    this.srcName = this.imgList[this.$store.state.wallet.mathRandom].src
    this.timer = setInterval(() => {
      if (document.readyState === 'complete') {
        window.clearInterval(that.timer);
        that.timer = null
        setTimeout(() => {
          this.showLoading = false
        }, 500)
      } else {
        that.showLoading = true
      }
    }, 1000);
    //1小時更新一次天氣
    this.weathTimer =  setInterval(() => {
      setTimeout(()=>{
        console.log('更新天氣')
        this.getWeather()
      },0)
    }, 1000 * 5 * 60)
    //6小时更新
    this.typeTimer = setInterval(() => {
      setTimeout(()=>{
        this.getTimeType()
      },0)
    }, 1000 * 5 * 6)
    this.getWeather()
    this.getTimeType()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.weathTimer)
    clearInterval(this.typeTimer)
    this.timer = null
    this.weathTimer = null
    this.typeTimer = null
    if(window.ethereum) {
      window.ethereum.removeListener('accountsChanged',()=>{})
      window.ethereum.removeListener('chainChanged',()=>{})
    }
  },
  methods: {
    //获取服务器白天晚上状态
    getTimeType() {
      let that = this
      core.httpPost(apiUrl.getSysInfoByType, {
        type: 23
      }, {
        success: function (res) {
          that.$store.commit('weather/updateTimeType', res.response.value)
        },
        error: function (err) {
          clearInterval(that.typeTimer)
          that.typeTimer = null
        }
      })
    },
    //获取天气
    getWeather() {
      let that = this
      core.httpPost(apiUrl.findWeather, {}, {
        success: function (res) {
          //更新vuex里的值
          that.$store.commit('weather/updateWeather', res.response)
        },
        error: function (err) {
          clearInterval(that.weathTimer)
          that.weathTimer = null
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  .loading-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loading {
      padding: 36px 63px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 8px solid #000;
      background-color: #fff;
      font-size: desktop-vw(18px);
      img {
        width: 100px;
        height: 100px;
      }
      .loading_bottom {
        display: flex;
        align-items: flex-end;
        div {
          width: 5px;
          height: 5px;
          background-color: #000;
          animation: bouncing-loader 0.6s infinite alternate;
          margin-left: 3px;
          &:nth-child(1) {
            margin-left: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }
    }
  }
}
@keyframes bouncing-loader {
      from {
        opacity: 1;
        transform: translateY(0);
      }
      to {
        opacity: 0.1;
        transform: translateY(1px);
      }
    }
</style>
