<template>
  <div class="rain">
    <hr v-for="(item,i) in 100" :class="[ i === 99?'thunder':'' ]" :style="{ left:i!==99?Math.floor(Math.random() * windowWidth) + 'px':'',
     animationDuration: i!==99?0.2 + Math.random() * 0.3 + 's':'', animationDelay: i!==99?Math.random() * 5 + 's':''}" :key="i"/> 
  </div>
</template>

<script>
    export default {
        name: 'Rain',
        data() {
            return {
               windowWidth: window.innerWidth,
            }
        },
        mounted() {
            
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
.rain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
hr.thunder {
  border: unset;
  position: absolute;
  width: 100vw;
  height: 100vh;
  animation-name: thunder;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}

hr:not(.thunder) {
  width: 50px;
  border-color: transparent;
  border-right-color: rgba(255, 255, 255, 0.7);
  border-right-width: 50px;
  position: absolute;
  bottom: 100%;
  transform-origin: 100% 50%;
  animation-name: rain;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rain {
  from {
    transform: rotate(105deg) translateX(0);
  }
  to {
    transform: rotate(105deg) translateX(calc(100vh + 20px));
  }
}

@keyframes thunder {
  0% {
    background-color: transparent;
  }
  1% {
    background-color: white;
  }
  2% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  8% {
    background-color: transparent;
  }
}
</style>
