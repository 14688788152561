<template>
    <div class="totalBouns" v-if="list.length > 0">
        <div class="top">
            <img class="left" src="@/assets/img/Bonuspool.svg" alt="">
            <div class="right">
            <div class="title">{{ $t("content.total") }}</div>
<!--            <div class="context"> {{ list[0].money | numberToCurrency  }} <strong>$</strong></div>-->
            <div class="context">
                <countTo :startVal=oldMoney  :endVal='Number(list[0].money)' :duration='3000'></countTo>
                <strong>$</strong></div>
            <div class="date">{{ $t("content.award") }}:About {{ list[0].day > 0?`${list[0].day} day`:'today' }}
                <div class="tanhao">
                    <img src="@/assets/img/tanhao.svg" alt="">
                </div>
                <div class="totalRule">{{ $t('content.totalRule') }}</div>
            </div>
            </div>
        </div>
        <div class="bottom">
            <table>
                <thead>
                    <tr>
                        <th v-html='$t("content.poolLevel")' :class="[$i18n.locale === 'ja'?'ja':'']"></th>
                        <th v-html='$t("content.numberOfPeople")'></th>
                        <th v-html='$t("content.estimated")'></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in tableList" :key="i" :style="{backgroundColor: i%2===0?'#F2F2F2':'#fff'}">
                        <td>Lv{{ item.level }}</td>
                        <td>{{ item.sum }}</td>
<!--                        <td>{{ item.money | numberToCurrency }}U</td>-->
                        <td><countTo :startVal="Number(oldList[i+1]?.money)|| 0" :endVal='Number(item.money)' :duration='2000' :decimals=2 unit="U"></countTo></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import countTo from '@/components/vue-countTo.vue'
    export default {
        name: 'totalBonus',
        data() {
            return {
              list: [],
              oldList: [],
              timer: null
            }
        },
      components: {
        countTo
      },
        computed: {
            // eslint-disable-next-line vue/return-in-computed-property
            tableList() {
                if(this.list.length>0) {
                    return this.list.filter(item=>item.level>0)
                }
            },
            oldMoney() {
                if(this.oldList.length>0) {
                    return Number(this.oldList[0].money)
                } else {
                    return 0
                }
            },

        },
        methods: {
            getTotalFunds() {
                const that = this
                this.oldList = this.list
                core.httpPost(this.apiUrls.getTotalFunds,{
                        
                    },
                    {
                        success: (res, code) => {
                            that.list = res.response
                        },
                        error: (res, code) => {
                            window.clearInterval(that.timer)
                            that.timer = null
                        }
                })
            }
        },
        mounted() {
           this.getTotalFunds()
           this.timer = window.setInterval(() => {
            setTimeout(this.getTotalFunds(), 0)
           }, 1000*5)
        },
        created() {
            
        },
      beforeDestroy() {
        window.clearInterval(this.timer)
        this.timer = null
      },
    //   destroyed() {
    //     window.clearInterval(this.timer)
    //     this.timer = null
    //   }
    }
</script>

<style lang="scss" scoped>
    .totalBouns {
        display: flex;
        flex-direction: column;
        border: 6px solid #000;
        min-width: 320px;
        .top {
            background-color: #1C2257;
            padding: 8px;
            display: flex;
            align-items: center;
            border-bottom: 6px solid #000;
            .left {
                width: 71px;
                height: 62px;
            }
            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 13px;
                .title {
                    font-size: desktop-vw(11px);
                    color: #fff;
                }
                .context {
                    font-size: desktop-vw(29px);
                    //color加漸變色
                    background-image:-webkit-linear-gradient(91.53deg, #FFE500 -8.96%, #FFFACB 35.59%, #FFEF5C 81.12%, #FFF9C2 108.6%);    
                    -webkit-background-clip:text;     
                    -webkit-text-fill-color:transparent;
                    display: flex;
                    align-items: center;
                    span {
                        font-family: fantasy;
                    }
                    strong {
                        display: inline-block;
                        font-size: desktop-vw(22px);
                        line-height: 21px;
                    }
                }
                .date {
                    font-size: desktop-vw(11px);
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    position: relative;
                    color:#fff;
                    cursor: url('@/assets/img/zhizhen1.cur'), auto;
                    .tanhao {
                        width: 15px;
                        height: 15px;
                        margin-left: 5px;
                        display: inline-block;
                    }
                    .totalRule {
                        position: absolute;
                        display: none;
                        border: 2px solid #000;
                        background-color: #fff;
                        padding: 7px;
                        font-size: desktop-vw(12px);
                        width: 400px;
                        white-space: normal;
                        left: 110%;
                        color: #000;
                    }
                    &:hover {
                        .totalRule {
                            display: block;
                        }
                    }
                }
            }
        }
        .bottom {
            background:#fff;
            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                thead {
                    background-color: #fff;
                    th {
                        // height: 28px;
                        line-height: 20px;
                        font-size: desktop-vw(12px);
                        color: #002562;
                        text-align: left;
                        padding-top: 7px;
                        padding-left: 15px;
                        padding-bottom: 7px;
                        width: auto;
                        min-width: 78px;
                        &:nth-child(2) {
                            padding-left: 0;
                        }
                        &:nth-child(3) {
                            text-align: right;
                            padding-right: 10px;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            height: 16px;
                            line-height: 16px;
                            font-size: desktop-vw(12px);
                            color: #000;
                            text-align: left;
                            padding-left: 14px;
                            padding-top: 4px;
                            padding-bottom: 2px;   
                            // width: auto;
                            min-width: 40px;
                            white-space: nowrap;
                            &:nth-child(2) {
                            padding-left: 0;
                         }
                         &:nth-child(3) {
                            padding-right: 10px;
                            min-width: 20px;
                            text-align: right;
                         }
                        }
                    }
                }
            }

        }
        .ja {
            min-width: 80px;
        }
    }
</style>