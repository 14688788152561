
import Vue from "vue"; // 引入Vue
import VueI18n from "vue-i18n"; // 引入国际化的包

Vue.use(VueI18n); // 全局注册国际化包


// 准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: localStorage.getItem('lang')||"zh", // 从cookie中获取语言类型 获取不到就是中文
    messages: {
      'zh': Object.assign(require('./zh.js')) ,   // 中文语言包
      'en': Object.assign(require('./en.js')) ,  // 英文语言包
      'ja': require('./ja.js'),    // 日文语言包
    }
  }); 
   
  export default i18n