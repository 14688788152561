<template>
  <div class="home">
    <div class="cloud"></div>
    <div class="cloud2"></div>
    <div class="ground"></div>
    <rain class="rain" v-if="rainShow"/>
    <header-view></header-view>
    <div class="main">
      <div class="page">
        {{ $t('content.homeGreeting') }}
      </div>
      <div class="ballon_one">
        <!-- <img :src="`${$imgUrl}${Math.floor(Math.random() * 1300 + 1)}.png`" alt=""> -->
        <img :src="`${$imgUrl}${Math.floor(Math.random() * 1300 + 1)}.gif`" alt="">
      </div>
      <div class="ballon_two">
        <!-- <img :src="`${$imgUrl}${Math.floor(Math.random() * 1200 + 1400)}.png`" alt=""> -->
        <img :src="`${$imgUrl}${Math.floor(Math.random() * 1200 + 1400)}.gif`" alt="">
      </div>
      <div class="ballon_three">
        <!-- <img :src="`${$imgUrl}${Math.floor(2700 + Math.random()*1300)}.png`" alt=""> -->
        <img :src="`${$imgUrl}${Math.floor(2700 + Math.random()*1300)}.gif`" alt="">
      </div>
      <div class="ballon_four">
        <!-- <img :src="`${$imgUrl}${Math.floor(4001 + Math.random()*1300)}.png`" alt=""> -->
        <img :src="`${$imgUrl}${Math.floor(4001 + Math.random()*1300)}.gif`" alt="">
      </div>
      <div class="ballon_five">
        <!-- <img :src="`${$imgUrl}${Math.floor(5001 + Math.random()*1300)}.png`" alt=""> -->
        <img :src="`${$imgUrl}${Math.floor(5001 + Math.random()*1300)}.gif`" alt="">
      </div>
      <total-bonus class="bonus"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import headerView from '@/components/header.vue'
import totalBonus from '@/components/totalBonus.vue'
import Rain from '@/components/rain'

export default {
  name: "HomeView",
  components: {
    headerView,
    totalBonus,
    Rain,
  },
  data() {
    return {
      endTime: (new Date("2023/06/19 16:59:10").getTime() / 1000).toString(),
      weaponShow: false,
      num: 0,
      accredit: false,
      offset: 0,
      rainShow: false,
      offset1: 0,
      offset2: 0,
      animateId: '',
      animateId2: '',
    };
  },
  computed: {
    accountStr() {
      let str1 = this.$store.state.wallet.account.substring(0, 5)
      let str2 = this.$store.state.wallet.account.substring(this.$store.state.wallet.account.length, this.$store.state.wallet.account.length - 4)
      return str1 + '...' + str2
    }
  },
  methods: {
    getTimeState() {
      const that = this
      // 判断当前时间段是否为晚上
      let imageUrl = "";
      let imageGround = "";
      let color = "";
      console.log(this.$store.state.weather.info,'1234')
      let time = this.$store.state.weather.timeType === '2' ? "evening" : "morning"; // 可以是 "morning" 或 "evening"
      let weatherList = this.$store.state.weather.info
      let weather = weatherList.find(v => v.mpId === 1)?.code === '1001' ? 'rainy' : 'sunny'; // 可以是 "sunny", "rainy" 等
      let actions = {
        "morning": {
          "sunny": function () {
            that.rainShow = false
            // 白天晴天
            imageUrl = require("@/assets/img/summerYun.png");
            imageGround = require("@/assets/img/summer.png");
            color = "#2c9ad0"
          },
          "rainy": function () {
            // 白天雨天
            that.rainShow = true
            imageUrl = require("@/assets/img/rain_cloud.png");
            imageGround = require("@/assets/img/rain_summer.png");
            color = "#737373"
          },
          // 其他天气情况
        },
        "evening": {
          "sunny": function () {
            that.rainShow = false
            imageUrl = require("@/assets/img/summer_nightCloud.png");
            imageGround = require("@/assets/img/summer_night.png");
            color = "#1C2440"
          },
          "rainy": function () {
            that.rainShow = true
            imageUrl = require("@/assets/img/rain_nightCloud.png");
            imageGround = require("@/assets/img/rain_night.png");
            color = "#24262C"
          },
          // 其他天气情况
        }
      }
      // 执行对应的函数
      actions[time][weather]();
      document.querySelector(".home").style.backgroundColor = color;
      document.querySelector(".cloud").style.backgroundImage =
          "url(" + imageUrl + ")";
      document.querySelector(".cloud2").style.backgroundImage =
          "url(" + imageUrl + ")";
      document.querySelector(".ground").style.backgroundImage =
          "url(" + imageGround + ")";
    },
    flutter() {
      this.offset += 0.8;
      //更改背景图位置
      document.querySelector(".cloud").style.backgroundPosition =
          this.offset + "px 100%";
      if (this.offset >= document.body.clientWidth) {
        this.offset = -document.body.clientWidth;
      }
      this.animateId = window.requestAnimationFrame(this.flutter);
    },
    getToken(ticket) {
      const that = this
      let type = this.$store.state.wallet.walletType.toLowerCase()
      core.httpPost(this.apiUrls.getUBoxToken, {
            ticket: ticket,
            type: type,
          },
          {
            success: function (res) {
              window.history.replaceState(null, null, that.gelDelURL());
              if(type === 'mp') {
              that.$store.commit("wallet/updateToken", res.response);
              that.$store.commit("wallet/updateUid", res.response);
              } else {
              that.$store.commit("wallet/updateToken", res.response.userToken);
              that.$store.commit("wallet/updateUid", res.response.openUid);
              }
              //跳转页面
              that.$router.push({path: '/game'})
            },
            error: function (res) {
              window.history.replaceState(null, null, that.gelDelURL());
              that.$message({
                message: that.$t("content.ticketError"),
                type: "error",
              })
            }
          })
    },
    gelDelURL() {
      return window.location.href.substring(0, window.location.href.indexOf("?"));
    },
    getRequest (key) {
      // 获取参数
      var url = window.location.search
      // 正则筛选地址栏
      var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
      // 匹配目标参数
      var result = url.substr(1).match(reg)
      // 返回参数值
      return result ? decodeURIComponent(result[2]) : null
    },
  },
  mounted() {
    let mainWidth = document.body.clientWidth;
    this.offset = -(mainWidth);
    this.offset2 = -(document.body.clientHeight);
    requestAnimationFrame(this.flutter);
    this.getTimeState()
    console.log(this.getRequest('userName'), '123')
  },
  //销毁
  beforeDestroy() {
    window.cancelAnimationFrame(this.animateId);
    this.animateId = '';
  },
  created() {
    let list = []
    // console.log(this.getParam(window.location.href),'url1234')
    if(this.$store.state.wallet.walletType === 'MP') {
      this.$store.commit("wallet/updateUserName", this.getRequest('userName'));
      list = window.location.href.split('uid=')
      console.log(list,'12345')
    if (list.length > 1) {
      console.log(window.location.href.split('uid='),'12345')
      let ticket = list[1]
      console.log(ticket, '1234')
      this.getToken(ticket)
     }
    } else if(this.$store.state.wallet.walletType === 'UBOX') {
      list = window.location.href.split('ticket=')
    if (list.length > 1) {
      console.log(window.location.href.split('ticket='),'12345')
      let ticket = list[1]
      console.log(ticket, '1234')
      this.getToken(ticket)
     }
    }
  },
  watch: {
    '$store.state.weather.timeType': function (newVal, oldVal) {
      this.getTimeState();
    },
    '$store.state.weather.info': function (newVal, oldVal) {
      this.getTimeState();
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  .page {
    padding: 86px 65px 72px 66px;
    border: 8px solid #000;
    font-size: desktop-vw(30px);
    line-height: 60px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;  
  }

  .cloud {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .cloud2 {
    position: absolute;
    z-index: -1;
    top: -100%;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .rain {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .ground {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 672px;
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 100% 100%;
    width: 100%;
    z-index: -1;
  }

  .main {
    padding: 0 18.5px;
    flex: 1;
    position: relative;
    // .time {
    //   position: absolute;
    //   top: 15px;
    //   left: 18.5px;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }
    .ballon_one {
      position: absolute;
      left: -60px;
      top: 282px;
      width: 429px;
      height: 429px;
      animation: identifier 6s linear 1s infinite reverse;
    }

    .ballon_two {
      position: absolute;
      left: 418px;
      top: 301px;
      width: 285px;
      height: 285px;
      animation: identifier 6s linear 1s infinite reverse;
      animation-delay: 1.5s;
    }

    .ballon_three {
      position: absolute;
      right: 444px;
      bottom: 317px;
      width: 188px;
      height: 188px;
      animation: identifier 6s linear 1s infinite reverse;
      animation-delay: 2s;
    }

    .ballon_four {
      //定位垂直居中
      position: absolute;
      left: 50%;
      top: 252px;
      height: 416px;
      width: 416px;
      margin-left: -208px;

      img {
        animation: identifier 6s linear 1s infinite reverse;
        animation-delay: 2.5s;
      }

      .weapon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;

        .box {
          width: 60px;
          height: 60px;
          background-color: #A0A9BE;
          border: 8px solid #fff;
          margin-left: 24px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .ballon_five {
      position: absolute;
      right: 53px;
      top: 301px;
      width: 359px;
      height: 359px;
      animation: identifier 6s linear 1s infinite reverse;
    }

    .bonus {
      position: absolute;
      bottom: 20px;
      z-index: 20;
    }
  }
}
</style>
