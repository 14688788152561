import Vue from "vue"
import $ from 'jquery'
import {MessageBox} from "element-ui";
export default {
    httpPost: function(url, params, opts) {
        var loading = null;
        var showLoading = false;
        if (opts.loading) {
            showLoading = opts.loading;
        }
        var isAsync = true;
        if (("async" in opts) && opts.async == false) {
            isAsync = false;
        }
        /* 生成access_token并添加到请求参数中 */
        var paramArray = [];
        if (params == null) {
            params = {};
        }
        Object.keys(params).forEach(function(key) {
            if ($.type(params[key]) === 'array' && params[key].length == 0) {
                return;
            }
            if (params[key] == null || $.type(params[key]) === 'object' && $.isEmptyObject(params[key])) {
                paramArray.push(key + '=');
            } else {
                paramArray.push(key + '=' + params[key]);
            }
        });
        paramArray = paramArray.sort();
        var paramStr = paramArray.join('&');
        paramStr += ('&__slat=' + 'sjwmjgMlwQsde2341JmqlFlafe81dFa12f');
        // console.error('requestUrl: ' + url + '?' + paramStr);
        var access_token = md5(paramStr);
        params['access_token'] = access_token;
        // 发起请求
				var dataType = 'json';
				if(opts.dataType){
					console.log(opts.dataType)
					dataType = opts.dataType
				}
        $.ajax({
            url: url,
            dataType: dataType,
            async: isAsync,
            data: params,
            traditional: true,
            // timeout: opts.timeout ? opts.timeout : 10000,
            type: 'POST',
            beforeSend: function() {
                if (showLoading) {
                    loading = Vue.prototype.$loading({
                        text: '正在处理，请稍候。。。',
                        spinner: 'el-icon-loading',
                        customClass: 'my-loading-mask'
                    });
                }
            },
            complete: function() {
                delete params['access_token'];
                if (showLoading) {
                    loading.close();
                }
            },
            success: function(res) {
                delete params['access_token'];
                if (res.code == 'SUCCESS' || res.errorCode == '0') {
                    opts.success(res);
                } else {
                    if (res.code == "ERROR") {
                        var isContinue = true;
                        if (opts.error) {
                            isContinue = opts.error(res);
                        }
                        if (isContinue) {
                            var response = res.response;
                            var message = '操作失败';
                            if (response) {
                                if ($.type(response) === 'string') {
                                    message = response;
                                } else if ($.type(response) === 'object') {
                                    message = response.message ? response.message : message;
                                } else if ($.type(response) === 'array') {
                                    message = response.length > 0 ? response[0].message : message;
                                }
                            }
                            Vue.prototype.$message({type: 'warning',message: message});
                        }
                    }
                    if (res.code == "NEED_LOGIN") {
                        if (opts.login) {
                            opts.login(res);
                        }
                        $('.el-dialog').remove();
                        $('.el-dialog__wrapper').remove();
                        $('.el-tooltip__popper').remove();
                        $('.v-modal').remove();
                        sysInfo.clear();
                        router.push("/login");
                    }
                }
            },
            error: function(res) {
                //alert(res.code)
                let isContinue = true;
                if (opts.failure) {
                    isContinue = opts.failure(opts);
                }
                if (isContinue) {
                    Vue.prototype.$hideLoading();
                    Vue.prototype.$message({type: "error",message: 'network error'});
                }
            }
        });
        return;
    },
}