<template>
    <div class="mask-box" v-show="show">
      <div class="popup-content">
        <div class="dialog_header" v-if="!orderHeader">
            {{ title }}
            <img class="close_btn" v-show="showBtn" @click="close" :style="{marginLeft:title?0:'auto'}" src="@/assets/img/close.svg" alt="" />
        </div>
        <div class="dialoglog_header_order" v-else>
            {{ title }}
            <div class="order">
                <img src="@/assets/img/Treasure.png" alt="">
                <p @click="order">Treasure Box Order</p>
            </div>
            <img class="close_btn" @click="close" src="@/assets/img/close.svg" alt="" />
        </div>
        <div class="dialog-box">
          <slot  v-if="show"></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomDialog",
    props: {
      show: Boolean,
      title: {
        type: String,
        default: '',
      },
        orderHeader: {
            type: Boolean,
            default: false,
        },
        showBtn: {
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
  
      }
    },
    created() {
    },
    mounted() {
        
    },
    watch: {
      show(val) {
        if (!val) {
          this.$emit('close', (callback) => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      }
    }
  },
    methods: {
      close() {
        // this.$emit('close', (callback) => {
        //   if (typeof callback === 'function') {
        //     callback()
        //   }
        // })
        this.$emit("update:show", false);
      },
        order() {
            this.$emit('order', (callback) => {
            if (typeof callback === 'function') {
                callback()
            }
            })
            this.$emit("update:show", false);
        }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .mask-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &::after {
      content: '';
      background: transparent;
      display: block;
      width: 100%;
      height: inherit;
    }
  
    .popup-content {
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 3000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      background: #fff;
      border: 8px solid #000;
      min-width: 496px;
    }
  
    // 自定义边框
    @mixin custom-border($position: 10px) {
      background: rgba(64, 70, 78, 0.4);
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        left: $position;
        top: -$position;
        border: 0.5px solid rgba(255, 255, 255, 0.4);
        border-bottom: none;
      }
  
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        left: $position;
        bottom: $position;
        border: 0.5px solid rgba(255, 255, 255, 0.4);
        border-top: none;
      }
    }
    .dialog_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        font-size: desktop-vw(27px);
        width: 100%;
        // height: 42px;
        .close_btn {
            width: 51px;
            height: 42px;
            cursor: url('@/assets/img/zhizhen1.cur'), auto;
        }
    }
    .dialoglog_header_order {
        display: flex;
        align-items: center;
        position: relative;
        font-size: desktop-vw(27px);
        width: 100%;
        height: 42px;
        .order {
            display: flex;
            align-items: center;
            margin-left: 28px;
            img {
                width: 27px;
                height: 18px;
                margin-right: 6px;
            }
            p {
                font-size: desktop-vw(15px);
                color: #707070;
                text-decoration: underline;
                cursor: url('@/assets/img/zhizhen1.cur'), auto;;
            }
        }
        .close_btn {
            width: 51px;
            height: 42px;
            position: absolute;
            right: 0;
            top: 0;
            cursor: url('@/assets/img/zhizhen1.cur'), auto;;
        }
    }
    .dialog-box {
      width: 100%;
      position: relative;
      color: #000;
      padding-top: 20px;
      //margin-right: 25px;
      //@include custom-border;
      background: #fff;
      border-radius: 4px;
    }
  }
  </style>