const state = {
    number: '',
    info: [],
    timeType: '',
}
const mutations = {
    updateWeather(state,val) {
        state.number = val.number
        state.info = val.info
    },
    updateTimeType(state,val) {
        state.timeType = val
    }
}
const actions = {

}
const getters = {

}
export default {
    //namespaced:true 开启命名空间
    state,mutations,actions,getters,namespaced:true
}