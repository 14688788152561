import Web3 from "web3";
import Vue from "vue"
import {MessageBox} from "element-ui";
import i18n from "@/lang/index";
import $store from '../store'

export default {
    async Init(callback) {
        //判断用户是否安装MetaMask钱包插件
        if (typeof window.ethereum === "undefined") {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.location.href = 'dapp://www.dmgang.com'
            } else {
                //没安装MetaMask钱包进行弹框提示
                MessageBox.confirm(`${i18n.t('content.install')}`, '', {
                    center: true,
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then(() => {
                    window.open('https://metamask.io/download/')
                }).catch(() => {

                })
            }
        } else {
            let chainId = await window.ethereum.request({method: 'eth_chainId'});
            console.log(chainId,'id')
            // if (chainId !== '0x5a2') {
            if(chainId !== '0x89'){    
                // Message({
                //     showClose: true,
                //     message: i18n.t('home.changeNetWork'),
                //     type: 'warning',
                // })
                Vue.prototype.$message({type:'error',message: i18n.t('metamask network')});
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        // params: [{chainId: '0x5a2'}],
                        params: [{chainId: '0x89'}],
                    }).then((res) => {
                        console.log('当前小狐狸节点:', window.ethereum.networkVersion)
                    })
                } catch (err) {
                    console.log(err, 'err')
                    if (err.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    // {
                                    //     chainId: '0x5a2',
                                    //     chainName: 'Polygon zkEVM Testnet',
                                    //     nativeCurrency: {
                                    //         name: 'ETH',
                                    //         symbol: 'ETH',
                                    //         decimals: 18,
                                    //     },
                                    //     rpcUrls: ['https://rpc.public.zkevm-test.net'] /* ... */,
                                    //     blockExplorerUrls: ['https://testnet-zkevm.polygonscan.com'],
                                    // },
                                    {
                                        chainId: '0x89',
                                        chainName: 'Polygon Mainnet',
                                        nativeCurrency: {
                                            name: 'MATIC',
                                            symbol: 'MATIC',
                                            decimals: 18,
                                        },
                                        rpcUrls: ['https://polygon-rpc.com/'] /* ... */,
                                        blockExplorerUrls: ['https://polygonscan.com/'],
                                    },
                                    // {
                                    //     chainId: '0x38',
                                    //     chainName: 'BNB Smart Chain',
                                    //     nativeCurrency: {
                                    //         name: 'BNB',
                                    //         symbol: 'BNB',
                                    //         decimals: 18,
                                    //     },
                                    //     rpcUrls: ['https://bsc-dataseed.binance.org/'] ,
                                    //     blockExplorerUrls: ['https://bscscan.com/'],
                                    // },
                                ],
                            });
                        } catch (addError) {
                            // handle "add" error
                            console.log(addError, 'err')
                        }
                    }
                }
            }
            // console.log(Web3.utils.hexToNumberString(chainId),chainId,networkVersion)
            //如果用户安装了MetaMask，你可以要求他们授权应用登录并获取其账号
            chainId = await window.ethereum.request({method: 'eth_chainId'})
            // if (chainId !== '0x5a2') return
            if (chainId !== '0x89') return
            window.ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                // 判断是否连接以太
                console.log('当前小狐狸节点:', window.ethereum.networkVersion)
                // let web3 = new Web3(window.ethereum)
                // if (window.ethereum.networkVersion !== desiredNetwork) {}
                // let currentProvider = web3.currentProvider;
                // web3.setProvider(currentProvider);
                callback(accounts[0])
            }).catch((reason) => {
                //如果用户拒绝了登录请求
                if (reason.code === 4001) {
                    // 用户拒绝登录后执行语句；
                    callback()
                    Vue.prototype.$message({type:'error',message: i18n.t('metaMask.loginError')});
                } else {
                    // 本不该执行到这里，但是真到这里了，说明发生了意外
                    // MessageBox.confirm("您登录时出现了问题", '', {
                    //     showClose: false,
                    //     showConfirmButton: false,
                    //     showCancelButton: false,
                    //     center: true
                    // });
                    Vue.prototype.$message({type: 'error',message: 'metamask error'});
                }
            })
        }
    }
}