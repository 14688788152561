/**en.js文件**/
module.exports = {
    content: {
        wallet: 'Connect Wallet',
        time: 'Start Countdown',
        merge: 'Merge',
        treasure: 'Treasure box',
        pool: 'Prize Pool',
        total: 'Total Bonus',
        rank: 'Ranking',
        height: 'Height',
        volume: 'Volume',
        speed: 'Speed',
        price: 'Price',
        amount: 'Amount',
        accredit: 'Authorized',
        buy: 'Buy',
        connect: 'Connect Wallet',
        login: 'Log In',
        award: 'Award Date',
        mining: 'Mining preparation in progress',
        walletTip: 'Please connect the wallet',
        pleaseAccredit: 'Please authorize',
        uboxTip: 'Please log in to Metapeak',
        connected: 'Wallet connected',
        signUbox: 'Signed in to Metapeak',
        poolLevel: 'Pool<br/>Level',
        numberOfPeople: 'Number<br/>of winners',
        estimated: 'Estimated<br/>Bonus',
        noOpen: 'Not yet open',
        settled: 'Settled',
        obtain: 'Obtaining bonus',
        orderRecord: 'Order Record',
        props: 'Obtain props',
        netWork: 'Please connect to the correct network',
        noWallet: 'Please connect wallet first', 
        noBallonId: 'This balloon has no associated treasure box',
        noLogin: 'Token expired, please log in again',
        noChainId: 'Please connect to the correct network',
        noPrice: 'Blockchain network exception, unable to obtain the price of the treasure box',
        waitGame: 'game starting soon',
        noAccredit: 'Authorization rejected',
        noPay: 'Payment rejected',
        noBallon: 'No Balloon',
        opening: 'Opening',
        noMoney: 'The transfer amount exceeds the authorized amount',
        ruleContent: 'Quickly understand the rules',
        Ballon: 'My Balloons',
        miningBallon: 'Mining',
        gameBallon: 'Playing',
        harvestBallon: 'winner balloon',
        equipped: 'Equipped',
        chongqinomal: 'gas can',
        fangyu: 'Rainpro of coating',
        huojian: 'Rocket',
        superhuojian: 'Super rocket',
        upzhitongche: 'Through train ticket',
        di_xc: 'small gas can',
        dj_dc: 'large gas can',
        dj_zc: 'medium gas can',
        dj_js: 'accelerator',
        noBallonTip: 'Your balloon was not found.<br/>Please go to NFT market <br/>to purchase a balloon',
        purchased: 'purchased',
        ToUbox: 'To Metapeak',
        chestWay: 'The treasure box is on its way~',
        continue: 'Continue buying',
        noAccredit2: 'The transfer amount exceed than the authorized amount, please reauthorize',
        noUse: 'Automatically effect after the game starts',
        totalRule: 'We employ a decentralized rule for distributing prize money, with a reward cycle based on Ethereum generating approximately 30,000 blocks (about 5.12 days). The method of determining the distribution ratio is as follows: calculation using modulo and hashed random numbers, resulting in the distribution ratio for this round.',
        validity: 'Validity',
        received: 'Received',
        chestWay2: 'The treasure box is on its way. Please be patient',
        ballonNo: 'Balloon number',
        ballonContract: 'Balloon contract address',
        ballonSpeed: 'Current ascending velocity',
        ballonMaterials: 'Balloon material',
        meterial: 'Carbon fiber and rubber',
        ballonQuality: 'Balloon material quality',
        ballonGas: 'Gas inside the balloon',
        gasInside: 'Helium and nitrogen',
        gasDensity: 'Gas density inside the balloon',
        ballonCurrentVolume: 'Current volume of the balloon',
        hkWeather: 'HK Weather',
        temperature: 'Temperature',
        humidity: 'Humidity',
        airDensity: 'Air density',
        windSpeed: 'Wind Speed',
        windRain: 'Raining',
        sunnyNight: 'Sunny Night',
        weatherWind: 'Windy',
        rule: 'Rule',
        sunny: 'Sunny',
        remainning: 'Remaining Time',
        startvalidity: 'Equipment effected',
        recordTip: 'mining successful！',
        miningRecords: 'Mining Rewards',
        recordSuccess: ' balloons have been placed in your backpack',
        features: 'Balloon Features',
        ticketError: 'Timed Out',
        passHeight: 'Reach this altitude to get the balloon',
        find: 'Find',
        information: 'Racing information',
        playBall: 'Racing Balloon',
        cutBall: 'Mining Balloon',
        enterBall: 'Enter balloon number please',
        mined: 'winner balloon',
        noProperty: 'No Property',
        racing: '22-Hour Jackpot Showdown',
        rankList: 'Ranking List',
        myRank: 'My Ranking',
        advanced: 'Up grated',
        youBallon: 'Your balloon',
        ranked: 'ranked {rank} in this level',
        failAdvance: 'Promotion failed',
        raise: ' Failed to reach to {meter}M',
        fialTip: 'Tip: Improving balloon attributes can increase balloon Speed',
        continueGame: 'Continue game',
        competitionBegin: 'The Lv{level} competition is about to begin',
        winHigher: 'Win higher bonuses',
        congratulations: 'Congratulations',
        winTip: 'Be Promoted Successfully',
        championTip: 'Currently, there are {number} people in the prize pool sharing Now you have the right to make a permanent pledge here and share the prize from it.',
        pledge: 'staking',
        obtaining: 'To obtain prize',
        workHard: 'Working hard to mine',
        toLevel: 'to level up',
        toPool: 'to prize pool',
        openError: 'Failed to open the treasure box. Please go to the order list to open it',
        freeBox: 'Free',
        receiveSuccess: 'Successfully received the free treasure box',
        receiveFail: 'Failed to receive',
        end: 'This game has ended',
        use: 'OK',
        settle: 'Calculating result',
        nextGame: 'Distance to next game',
        noFind: 'No balloon information found for number {num}',
        settleTip: 'Congratulations!<br/>You have won the ultimate prize，<br/>The balloon has been automatically pledged!',
        go: 'Go for it',
        noRecords: 'No order records',
        useCar: 'Direct train used',
        pledgeSuccess: 'Successful pledge',
        pledgeFail: 'Failure of pledge',
        remainder: 'remainder',
        findBallon: 'Query Balloon',
        input: 'Input Number',
        install: 'please install metamask',
        homeGreeting: '"To the Moon 2.0" is a captivating NFT-based scientific competitive game, the first of its kind designed based on the principles of real aerodynamics, creating a fully decentralized web3 treasure-hunting game.',
        noChampion: 'Win the championship',
    },
    metaMask: {
        loginError: 'User rejected login'
    }     
} 