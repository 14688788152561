import loadingTem from "@/components/loading.vue"
import store from '../store'

const loading = {
  install:(Vue,options)=>{
    let loadingVue = Vue.extend(loadingTem);
    let vm = new loadingVue({store}); 
    document.body.appendChild(vm.$mount().$el);
    Vue.prototype.$showLoading = ()=>{
      vm.showloading = true;
    }
    Vue.prototype.$hideLoading = ()=>{
      vm.showloading = false;
    }
  }
}

export default loading;