<template>
    <div class="choose">
          <div class="choose_input" @click="selectShoe = !selectShoe">
            {{ language }}
            <img
              v-show="!selectShoe"
              class="sanjiao"
              src="@/assets/img/top.svg"
              alt=""
            />
            <img
              v-show="selectShoe"
              class="sanjiao"
              src="@/assets/img/bottom.svg"
              alt=""
            />
          </div>
          <ul v-show="selectShoe">
            <li
              v-for="(item, i) in list"
              @click="changeItem(item.value)"
              :key="i"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
</template>

<script>
    export default {
        name: "LanguageSelect",
        data() {
            return {
                language: "",
      selectShoe: false,
      list: [
        {
          name: "繁体中文",
          value: "zh",
        },
        {
          name: "English",
          value: "en",
        },
        {
          name: "日本語",
          value: "ja",
        },
      ],
            }
        },
        methods: {
            changeItem(val) {
      let item = this.list.find((item) => item.value === val);
      localStorage.setItem("lang", val);
      this.language = item.name;
      this.selectShoe = false;
      this.$i18n.locale = val;
    },
        },
        mounted() {
            if (!localStorage.getItem("lang")) {
      this.language = "繁体中文";
      localStorage.setItem("lang", "zh");
    } else {
      if (localStorage.getItem("lang") === "zh") {
        this.language = "繁体中文";
      } else if (localStorage.getItem("lang") === "en") {
        this.language = "English";
      } else {
        this.language = "日本語";
      }
    }
    document.addEventListener('click', (e) => {
        if (e.target.className !== 'choose_input') {
          this.selectShoe = false;
        }
      })
        },
        destroyed() {
      document.removeEventListener('click', () => {})
    },
    }
</script>

<style lang="scss" scoped>
.choose {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: auto;
        width: 120px;
        .choose_input {
          width: 100%;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          outline: none;
          font-size: desktop-vw(16px);
          cursor: url('@/assets/img/zhizhen1.cur'), auto;;
        }
        .sanjiao {
          width: 16px;
          margin-left: 5px;
        }
        ul {
          position: absolute;
          top: 40px;
          width: 100%;
          left: 0;
          color: #000;
          background-color: #fff;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          z-index: 10;
          li {
            width: 100%;
            height: 6vh;
            line-height: 6vh;
            text-align: center;
            font-size: desktop-vw(14px);
            font-weight: 600;
            cursor: url('@/assets/img/zhizhen1.cur'), auto;;
          }
        }
      }
</style>