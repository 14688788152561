import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

import wallet from './wallet/wallet'
import weather from './weather/weather'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules:{
      wallet,
      weather
  },
  plugins: [
      createPersistedstate({
        storage: window.sessionStorage,
    // 存储的 key 的key值
    key: "store",
    render(state) {
      // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
      return { ...state };
    }
      })
    ]
  
})

export default store
