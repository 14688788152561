import Vue from 'vue'
//防抖
Vue.directive('debounce', {
    inserted: function (el, binding) {
      const [fn, event, time] = binding.value
      let timer
      el.addEventListener(event, () => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          fn()
        }, time)
      })
    }
  })