import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    //路由懒加载
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/searchView.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('store')?JSON.parse(sessionStorage.getItem('store')).wallet?.token:''
  if(!token) {
    if(to.path === '/game') {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})
