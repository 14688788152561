/**ja.js文件**/
module.exports = {
    content: {
        wallet: 'ウォレット',
        time: 'カウントダウン',
        merge: 'マージ',
        treasure: '宝箱',
        pool: '賞金プール',
        total: '賞金総額',
        rank: 'ランキング',
        height: '高さ',
        volume: '体積',
        speed: '速度',
        price: '価格',
        amount: '数量',
        accredit: '授権額',
        buy: '購入',
        connect: 'リンクウォレットです',
        login: 'ログイン',
        award: '賞与日',
        mining: '採掘準備中です',
        walletTip: 'ウォレットをリンクしてください',
        uboxTip: 'Metapeakにログインしてください',
        connected: 'ウォレットがリンクされました',
        signUbox: 'Metapeakにログインしました',
        poolLevel: 'ブール<br/>レベル',
        numberOfPeople: '腾者数',
        estimated: '見積<br/>もり賞金',
        noOpen: 'まだ開いていません',
        settled: '決済済み',
        obtain: '賞金を獲得する',
        orderRecord: '注文記録',
        props: '宝箱を取得した',
        netWork: '正しいネットワークに接続してください',
        noWallet: 'まずウォレットをリンクしてください',
        noBallonId: 'このバルーンには関連する宝箱がありません',
        noChainId: '正しいネットワークに接続してください',
        noPrice: 'ブロックチェーンネットワークは異常となり、宝箱の価格は取得できない',
        waitGame: 'ゲームの開始<br/>を待っています',
        noAccredit: '授権を拒否しました',
        noPay: '支払いを拒否しました',
        noBallon: '風船がありません',
        opening: '開いています',
        pleaseAccredit: '授権してください',
        noMoney: '転送金額が授権金額を超えています',
        noLogin: 'トークンは無効です,再登録してください',
        ruleContent: '読書ルールのクイックマスターの紹介',
        Ballon: '私の風船',
        miningBallon: '採掘風船',
        gameBallon: '試合風船',
        harvestBallon: 'ご褒美風船',
        equipped: '装備済み',
        chongqinomal: 'リセット観です',
        fangyu: 'レインコート',
        huojian: 'ロケット',
        superhuojian: 'スーパーロケット',
        upzhitongche: '直通乗車券です',
        di_xc: 'ガスボンベです',
        dj_dc: '大ガスタンクです',
        dj_zc: '中ガスボンベです',
        dj_js: '加速器',
        noBallonTip: '風船が見つかりませんでした。<br/>NFTマーケットに行って<br/>購入してください。',
        purchased: '購入済み',
        ToUbox: 'NFTマーケットへ',
        chestWay: '購入が成功しました 宝箱が近づいています',
        continue: '買い続けます',
        noAccredit2: '送金金額が承認金額を上回りますので,再承認をお願いします',
        noUse: '試合開始後自動的に使用されます',
        totalRule: '私たちは賞金を分配するために分散型のルールを採用しており、報酬サイクルはイーサリアムが約30,000ブロック（約5.12日）を生成することを基にしています。分配比率の決定方法は次の通りです：モジュロおよびハッシュされたランダムな数値を用いた計算により、今回の分配比率が決定されます。',
        validity: '有効期間',
        received: '受け取り済み',
        chestWay2: '宝箱は途中です,お待ちください',
        ballonNo:'風船番号',
        ballonContract:'風船契約アドレス',
        ballonSpeed:'風船の現在の上昇速度',
        ballonMaterials:'風船材料',
        meterial:'炭素繊維とゴム混合物',
        ballonQuality:'風船材料の重さ',
        ballonGas:'風船内部気体',
        gasInside:'ヘリウムと窒素の混合物',
        gasDensity:'風船内部の気体密度',
        ballonCurrentVolume:'風船の現在の体積',
        hkWeather:'香港天気情報',
        temperature:'温度',
        humidity:'湿度',
        airDensity:'空気密度',
        windSpeed:'風速',
        windRain: '雨',
        sunnyNight: '晴れ渡った夜',
        weatherWind: '曇り',
        rule: 'ルール',
        sunny: '晴れ',
        remainning: '残り時間',
        startvalidity: '発動中',
        miningRecords: '採掘成功',
        recordTip: '採掘成功おめでとうございます！',
        recordSuccess: '個風船,私の風船にいれました',
        features: '風船属性です',
        ticketError: 'ticketは時間切れです',
        passHeight: 'この高さに達すると、風船が手に入れる',
        find: '確認',
        information: '試合情報',
        playBall: '風船勝負です',
        cutBall: '採堀風船',
        enterBall: 'クエリにはバルーン番号を入力します',
        mined: '掘出風船',
        noProperty: '道具がありません',
        racing: '22時間グランプリ対決',
        rankList: 'ランキング',
        myRank: '私の順位',
        advanced: '昇進に成功する',
        youBallon: 'あなたの',
        raise: ' {meter}Mにアップグレードできません',
        ranked: ' 今回の試合で第{rank}位を獲得しました',
        failAdvance: '昇進に失敗する',
        fialTip: 'ヒント: 進級に失敗した風船のプロパティを改善することで風船の速度を上げることができます',
        continueGame: 'ゲームを続ける',
        competitionBegin: '{level}級試合が始まる',
        winHigher: 'より高い賞金を獲得する',
        congratulations: 'おめでとう',
        winTip: '進級成功しました',
        championTip: '現在、共有する賞金プールには{number}人がいます。今、あなたはここで恒久的なステーキングを行い、それからボーナスプールの利益を得る権利があります。',
        pledge: 'ステーキング',
        obtaining: 'ステーキングを行い、賞金収入を得る',
        workHard: '鉱業に励んでいる',
        toLevel: '昇格ラインへ',
        toPool: 'ボーナスプールへ',
        openError: '宝箱のオープンに失敗しました。注文リストに行ってオープンしてください',
        freeBox: '無料',
        receiveSuccess: '無料宝箱を無事に受け取りました',
        receiveFail: '受け取り失敗',
        end: 'このゲームはもう終わりました',
        use: '使用',
        settle: '決済中',
        nextGame: '次の試合まで',
        noFind: '風船番号{num}の情報がみつかりません',
        settleTip: 'おめでとう!<br/>あなたは究極の賞を受賞しました，<br/>風船は自動的に質押された!',
        go: 'がんばって',
        noRecords: '注文記録がありません',
        useCar: '直通車利用済',
        pledgeSuccess: '質入れ成功です',
        pledgeFail: '質入れ失敗です',
        remainder: '残り時間',
        findBallon: '風船を検索',
        input: '番号を入力します',
        install: 'MetaMaskをインストールお願いします',
        homeGreeting: '"月に行く2.0"は魅力的なNFTベースの科学競技ゲームで、実際の空気動力学の原理に基づいて設計された最初のゲームで、完全に中心化されたweb 3宝探しゲームを創造した',
        noChampion: 'チャンピオンになりました',
    },
    metaMask: {
        loginError: 'ユーザーがログインを拒否しました',
    }    
}