import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/styles/common.scss";
import i18n from "@/lang/index";
import { numberToCurrencyNo } from '@/utils/numberToCurrency'
import MyDialog from '@/components/customDialog.vue'
import 'element-ui/lib/theme-chalk/index.css';
import Web3 from 'web3'
import core from '@/utils/http'
import apiUrls from "@/utils/apiUrl"
import md5 from '@/utils/md5.js'
import Message from '@/utils/ai-messageExtend.js'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import loading from "@/utils/loading.js"
import { getSecond } from '@/utils/date.js'
import '@/utils/directive'

Vue.use(FloatingVue)
Vue.use(loading) // 全局使用loading
Vue.use(Message)
Vue.config.productionTip = false
Vue.prototype.Web3 = Web3
Vue.prototype.apiUrls = apiUrls
Vue.prototype.$provider = ''
Vue.prototype.$signer = ''
Vue.prototype.$USDTContract = ''
Vue.prototype.$treasureContract = ''
Vue.prototype.$getSecond = getSecond
Vue.component('my-dialog', MyDialog)

window.md5 = md5
window.core = core

// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrencyNo)

Vue.prototype.$imgUrl = ''
Vue.prototype.$apikey = ''
  core.httpPost(apiUrls.getSysInfoByType,{
    type: '58'
  },{
    success: function(res) {
    Vue.prototype.$apikey = res.response.value
    },
    error: function(res) {
      Vue.prototype.$apikey = '' 
    }
  })
core.httpPost(apiUrls.getSysInfoByTypes, {
  types: '16,17,34,35'
},{
  success: function (res) {
    Vue.prototype.$imgUrl = res.response[0]?.value
    Vue.prototype.$imgUrlBg = res.response[2]?.value
    Vue.prototype.$imgGame = res.response[1]?.value
    Vue.prototype.$imgGameBg = res.response[3]?.value
      new Vue({
          router,
          store,
          i18n,
          render: h => h(App)
        }).$mount('#app')
  }
})
