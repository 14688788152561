const state = {
    account: '',
    token: '',
    uid: '',
    approveUsdt: '',
    approveUsdtOld: '',
    mathRandom: 0,
    walletType: '',
    userName: '',
    mouseList: [],
    mouseCount: 0,
    docmentVisibility: false,
 }
 const mutations = {
    updateAccount(state,val) {
     state.account = val
    },
    updateToken(state,val) {
        state.token = val
    },
    updateUid(state,val) {
        state.uid = val
    },
    updateApproveUsdt(state,val) {
        state.approveUsdt = val
    },
    updateApproveUsdtOld(state,val) {
        state.approveUsdtOld = val
    },
    updateMathRandom(state,val) {
        state.mathRandom = val
    },
    updateWalletType(state,val) {
        state.walletType = val
    },
    updateUserName(state,val) {
        state.userName = val
    },
    updateMouseList(state,val) {
        if(state.mouseList.length === 0) {
            state.mouseList.push(val)
            return
        }
        let index = state.mouseList.findIndex(v=>v.x === val.x&&v.y === val.y)
        if(index === -1) {
            state.mouseList.push(val)
        } else {
            state.mouseList[index].value++
        }           
    },
    updateMouseCount(state,val) {
        state.mouseCount = val
    },
    clearMouseList(state) {
        state.mouseList = []
    },
    updateDocmentVisibility(state,val) {
        state.docmentVisibility = val
    }
 }
 const actions = {
     
 }
 const getters = {
     
 }
 export default {
     //namespaced:true 开启命名空间
     state,mutations,actions,getters,namespaced:true
 }